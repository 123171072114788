import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState
} from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { NotificationReceivedEvent } from '../domain';
import { NS_CONFIG_TOKEN } from '../notification.types';

@Injectable()
export class SignalRService {
  private readonly config = inject(NS_CONFIG_TOKEN);
  private connection: HubConnection | undefined;

  private _newNotification$ = new Subject<NotificationReceivedEvent>();

  newNotification$ = this._newNotification$.asObservable();

  constructor() {
    if (!this.config.signalR.enabled) {
      return;
    }

    this.config.accessToken$
      .pipe(takeUntilDestroyed())
      .subscribe(accessToken => {
        if (accessToken) {
          this.createConnection(accessToken);
        }
      });
  }

  async startConnection(): Promise<void> {
    if (
      !this.connection ||
      this.connection.state === HubConnectionState.Disconnected
    ) {
      await this.connection?.start();
    }
  }

  private async createConnection(accessToken: string): Promise<void> {
    const signalRHubUrl = this.config.signalR.url;

    if (this.connection) {
      return;
    }

    this.connection = new HubConnectionBuilder()
      .withUrl(signalRHubUrl, {
        accessTokenFactory: () => Promise.resolve(accessToken),
        withCredentials: true
      })
      .withAutomaticReconnect()
      .build();

    this.registerListeners();

    await this.startConnection();
  }

  private registerListeners(): void {
    if (!this.connection) {
      return;
    }
    this.connection.on(
      'NotificationReceived',
      (eventData: NotificationReceivedEvent) => {
        this._newNotification$.next(eventData);
      }
    );
  }
}
